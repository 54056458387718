import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">

<path d="M7744 8766 c-148 -45 -316 -128 -563 -278 -64 -38 -138 -86 -166
-107 -27 -20 -67 -47 -87 -61 -20 -13 -35 -26 -33 -28 1 -2 61 26 131 61 260
130 523 217 659 217 53 0 102 -18 128 -48 80 -90 -32 -345 -306 -698 -48 -61
-96 -119 -107 -129 l-20 -18 0 261 0 262 -270 0 -270 0 0 -557 0 -557 -32 -34
c-51 -53 -365 -341 -444 -407 -107 -90 -188 -150 -197 -147 -5 1 -6 -1 -3 -6
5 -8 -47 -53 -59 -52 -4 1 -26 -17 -50 -39 -25 -23 -48 -41 -52 -41 -5 0 -57
50 -117 109 -97 98 -119 131 -84 131 7 0 6 -6 -2 -15 -18 -21 -3 -19 21 3 12
12 17 25 13 35 -7 18 -24 23 -24 7 0 -5 -13 -7 -30 -5 -16 2 -30 0 -30 -5 0
-15 18 -21 23 -8 2 7 7 10 11 7 4 -3 0 -11 -8 -18 -11 -9 -28 2 -89 59 -41 38
-76 70 -78 70 -2 0 -4 -216 -4 -479 l0 -479 -24 -26 c-14 -14 -22 -29 -18 -32
8 -9 28 6 22 16 -3 5 -1 11 4 14 5 3 9 -11 10 -32 0 -20 2 -44 3 -52 0 -8 0
-24 -1 -35 -2 -20 -2 -20 -16 -1 -18 24 -19 52 -2 60 6 3 2 3 -10 1 -12 -3
-24 -1 -28 4 -4 7 1 8 12 4 16 -6 16 -5 4 11 -8 9 -17 13 -20 9 -14 -19 -127
-66 -169 -71 -35 -4 -41 -7 -27 -12 15 -6 7 -9 -30 -15 -68 -9 -77 -13 -32
-14 22 -1 53 5 70 13 59 26 99 36 126 30 20 -4 23 -3 11 5 -13 10 -12 13 4 26
26 20 42 19 26 -1 -11 -12 -11 -15 1 -12 8 2 18 -10 23 -29 8 -27 15 -33 37
-33 21 0 28 6 31 22 2 12 9 23 16 26 11 3 12 0 3 -16 -9 -17 -7 -20 14 -25 14
-2 28 0 31 4 3 5 -2 9 -10 9 -9 0 -16 7 -16 15 0 8 5 15 10 15 6 0 10 -4 10
-10 0 -6 89 -10 246 -10 228 0 245 -1 242 -17 -2 -13 -13 -18 -38 -18 l-35 0
1 -52 c1 -45 -2 -52 -22 -62 -13 -6 -24 -17 -24 -25 0 -8 -5 -18 -10 -21 -7
-4 -9 2 -5 18 4 16 2 26 -7 30 -11 4 -13 -2 -10 -24 3 -24 0 -30 -23 -39 -32
-12 -35 -12 -35 -1 0 5 10 12 23 14 12 2 6 4 -14 3 -35 -1 -36 -2 -34 -38 2
-36 1 -38 -29 -39 -20 -1 -24 -3 -11 -6 38 -8 46 -13 40 -23 -5 -8 -12 -7 -23
2 -13 11 -14 11 -10 -2 3 -8 -1 -23 -10 -32 -11 -14 -12 -18 -2 -18 7 0 58 46
114 103 71 72 116 129 151 190 l50 86 243 1 c133 0 242 1 242 3 0 2 -82 85
-182 185 l-183 181 85 88 c47 49 137 144 200 212 179 194 262 278 251 255 -7
-14 -10 -152 -10 -370 1 -319 -1 -358 -21 -454 -37 -183 -122 -401 -199 -514
-17 -24 -31 -48 -31 -51 0 -18 -150 -193 -228 -266 -154 -144 -267 -220 -452
-302 -210 -92 -387 -129 -625 -130 -252 -1 -440 40 -685 150 -50 22 -197 111
-250 150 -88 66 -249 224 -314 307 -36 47 -66 87 -66 90 0 3 -16 31 -37 63
-43 68 -113 216 -113 241 0 23 460 472 483 472 2 0 2 -13 -1 -30 -3 -19 0 -30
8 -30 7 0 9 8 5 19 -4 13 2 28 17 43 13 13 22 26 21 30 -2 4 16 20 38 35 23
15 53 40 67 56 30 32 33 33 61 8 l22 -19 -22 -4 c-31 -4 -34 -23 -4 -26 17 -1
30 6 41 23 9 14 25 25 35 25 24 0 35 25 20 44 -9 11 -7 15 9 19 11 3 20 1 20
-4 0 -5 5 -9 10 -9 6 0 10 9 10 20 0 13 7 20 20 20 24 0 28 35 5 44 -10 3 -17
-3 -21 -19 -7 -27 -23 -32 -43 -12 -11 10 -13 9 -7 -1 4 -7 -5 -25 -24 -43
-25 -26 -33 -29 -45 -19 -13 11 -7 21 42 66 40 38 63 52 75 48 13 -4 16 -1 12
10 -3 9 3 19 18 27 20 11 22 11 10 -3 -11 -14 -9 -17 18 -23 24 -5 34 -2 49
16 10 12 23 19 29 15 19 -12 14 -33 -9 -39 -12 -3 -18 -10 -15 -16 4 -6 13 -8
20 -5 10 4 17 -12 25 -59 14 -80 13 -102 -2 -60 -11 29 -12 19 -12 -76 0 -60
3 -107 8 -104 4 2 7 -1 7 -6 0 -6 -4 -11 -9 -11 -4 0 -6 -11 -3 -25 9 -49 21
-24 16 33 -7 84 -7 84 -4 122 2 26 5 19 11 -30 12 -105 16 -121 38 -141 12
-11 21 -25 21 -30 0 -6 11 -25 25 -41 14 -17 23 -33 19 -37 -4 -3 -9 0 -11 7
-6 16 -20 15 -26 -1 -3 -9 5 -13 32 -13 36 1 36 1 36 -41 0 -36 3 -43 20 -43
11 0 16 4 10 8 -12 8 -16 42 -5 42 11 0 34 -30 24 -30 -5 0 -1 -5 9 -11 9 -5
21 -7 27 -4 5 3 14 -4 20 -15 13 -23 30 -27 30 -5 0 8 -15 22 -32 31 -61 31
-121 88 -162 153 -23 36 -41 68 -41 71 -1 3 -7 29 -14 59 -16 64 -10 208 12
261 8 19 17 44 21 54 6 17 30 -4 161 -134 l155 -155 0 538 0 537 -537 0 -538
0 184 -184 184 -184 -180 -188 c-99 -104 -229 -243 -289 -309 -226 -249 -268
-296 -280 -313 -7 -9 -16 -13 -21 -9 -16 16 -8 346 11 442 41 210 122 423 215
560 15 22 47 69 71 104 44 64 178 206 260 276 153 128 386 248 605 311 65 18
220 44 268 44 l37 0 0 265 0 265 -51 0 c-205 0 -573 -101 -819 -225 -188 -95
-513 -336 -494 -366 4 -7 3 -9 -3 -6 -11 7 -127 -109 -178 -178 -16 -22 -33
-42 -36 -45 -20 -17 -144 -210 -186 -290 -231 -441 -300 -942 -197 -1431 13
-64 33 -142 44 -174 11 -32 20 -65 20 -74 0 -9 -50 -84 -111 -166 -133 -179
-205 -286 -286 -420 -150 -249 -172 -304 -193 -498 -18 -161 13 -262 94 -308
63 -36 180 -33 304 9 103 35 311 136 442 215 82 49 325 210 345 229 5 5 -64
-26 -155 -68 -463 -214 -721 -250 -760 -105 -15 56 3 128 60 246 43 88 160
281 179 295 4 3 22 27 39 53 18 27 65 87 104 134 l71 85 20 -33 c185 -309 362
-504 630 -694 300 -212 603 -327 977 -370 419 -49 873 40 1248 247 84 46 269
177 357 252 111 95 282 281 333 363 15 24 36 56 48 71 110 143 243 457 294
689 46 211 50 289 50 970 l0 644 103 132 c206 264 425 586 535 788 59 108 85
222 86 384 1 149 -11 186 -77 239 -33 27 -46 30 -120 33 -65 2 -99 -2 -163
-21z m-674 -1940 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21
13z m-1240 -216 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
10 -4 10 -10z m-951 -297 c2 -1 -4 -14 -13 -28 -9 -14 -14 -30 -11 -35 3 -6 1
-10 -4 -10 -6 0 -11 8 -12 18 0 11 -2 12 -6 4 -3 -7 -1 -18 4 -25 5 -7 6 -28
2 -47 l-6 -35 -2 36 c0 19 -6 40 -12 46 -8 8 -6 19 5 39 8 16 15 23 16 16 0
-17 20 -5 20 12 0 18 -33 15 -42 -4 -5 -11 -7 -10 -7 5 -1 13 -8 21 -21 22
-11 1 -19 -4 -17 -10 1 -7 -11 -13 -28 -15 -27 -2 -28 -1 -13 11 10 8 15 17
11 21 -4 4 1 4 11 0 13 -5 17 -4 12 4 -5 8 -1 10 10 5 13 -5 16 -3 11 10 -4
12 -2 14 10 10 12 -5 14 -3 8 8 -4 8 9 -2 31 -21 21 -19 41 -36 43 -37z m-327
-197 c15 -22 7 -26 -17 -10 -14 9 -25 20 -25 25 0 11 33 -1 42 -15z m1611
-473 c3 -5 -68 -7 -156 -5 -302 4 -333 8 -90 10 133 1 244 -1 246 -5z m-53
-64 c0 -5 5 -7 10 -4 6 4 5 -5 -2 -19 -11 -26 -28 -35 -28 -16 0 6 5 10 10 10
7 0 7 6 0 20 -7 14 -7 20 0 20 5 0 10 -5 10 -11z m-100 -169 c-6 -11 -15 -20
-20 -20 -5 0 -21 -12 -35 -27 -14 -15 -25 -23 -25 -16 0 6 6 14 13 17 10 5 10
7 0 12 -15 7 -17 34 -3 34 6 0 10 -7 10 -16 0 -13 6 -11 33 9 39 32 40 32 27
7z"/>
<path d="M4810 6340 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5753 8189 c11 -7 108 -9 267 -7 l249 3 6 -23 c3 -12 5 -372 5 -798
-1 -596 -4 -774 -13 -771 -7 3 -10 11 -7 18 3 8 5 358 5 779 l0 765 -5 -755
-5 -755 0 765 0 765 -255 0 -255 0 5 -730 5 -730 3 723 2 722 240 0 240 0 2
-767 c0 -423 2 -779 3 -793 2 -34 -13 -52 -38 -44 -16 5 -19 3 -14 -11 5 -11
3 -15 -5 -12 -7 2 -12 10 -10 16 1 7 -2 10 -8 6 -8 -5 -8 -11 0 -20 8 -10 7
-14 -2 -18 -8 -3 -8 -6 -2 -6 6 -1 37 18 68 41 l56 41 0 804 0 803 -277 0
c-196 -1 -273 -4 -260 -11z"/>
<path d="M5737 6691 c-3 -11 1 -28 8 -37 11 -15 12 -14 11 14 -1 37 -12 50
-19 23z"/>
<path d="M5821 6571 c-12 -8 -11 -13 10 -30 14 -12 32 -18 42 -15 15 5 18 2
13 -13 -9 -27 40 -76 66 -68 14 5 17 2 16 -17 -2 -17 2 -23 19 -24 12 -1 20 2
18 6 -3 5 6 19 20 31 13 13 25 27 25 30 0 3 -31 5 -69 4 l-68 -2 1 41 1 41
-43 -1 c-33 -1 -42 2 -38 12 6 16 6 17 -13 5z m49 -31 c0 -5 -4 -10 -10 -10
-5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m40 0 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m0 -40 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m40 -40 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m40 -1 c0 -5
5 -7 10 -4 15 9 12 -7 -5 -29 -8 -11 -18 -16 -22 -12 -4 4 -1 12 7 17 11 8 12
12 3 16 -15 6 -17 23 -3 23 6 0 10 -5 10 -11z"/>
<path d="M6078 6473 c17 -6 15 -23 -4 -23 -16 0 -32 -14 -51 -45 -6 -11 -16
-19 -23 -17 -6 1 -9 -2 -5 -7 9 -15 33 -2 40 20 6 16 38 38 65 43 12 3 30 18
30 25 0 4 -15 8 -32 8 -18 0 -27 -2 -20 -4z"/>
<path d="M4635 6239 c-11 -16 -1 -19 13 -3 7 8 8 14 3 14 -5 0 -13 -5 -16 -11z"/>
<path d="M4744 6226 c-6 -17 11 -46 28 -46 14 0 5 53 -9 58 -7 2 -16 -4 -19
-12z"/>
<path d="M4724 6179 c-8 -14 18 -31 30 -19 9 9 0 30 -14 30 -5 0 -12 -5 -16
-11z"/>
<path d="M4471 6054 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4593 6044 c-49 -29 -53 -40 -18 -46 13 -3 22 -2 20 2 -3 4 2 13 10
20 8 7 15 9 15 5 0 -4 7 -2 15 5 16 13 21 40 8 40 -5 0 -27 -12 -50 -26z"/>
<path d="M4427 6046 c-4 -9 -2 -16 3 -16 20 0 9 -18 -15 -24 -14 -4 -25 -14
-25 -23 0 -15 2 -15 14 2 8 12 20 16 35 12 11 -3 19 -1 16 3 -3 5 0 10 7 12 8
3 5 12 -9 27 -17 19 -21 20 -26 7z"/>
<path d="M4396 6023 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M4338 5953 c-10 -3 -18 -9 -18 -14 0 -10 7 -11 34 0 23 9 9 19 -16
14z"/>
<path d="M4311 5874 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4836 5861 c-3 -5 5 -11 19 -13 20 -4 23 -3 14 8 -13 16 -25 18 -33
5z"/>
<path d="M4323 5828 c2 -7 12 -39 21 -70 9 -31 22 -63 29 -70 8 -7 12 -18 10
-24 -2 -5 6 -20 17 -32 12 -12 19 -26 16 -30 -2 -4 2 -16 10 -27 10 -14 14
-15 15 -4 3 31 5 36 23 55 18 17 18 19 2 22 -14 3 -15 6 -4 19 11 14 10 15
-10 9 -46 -15 -59 -1 -57 61 2 31 -1 68 -6 82 -7 21 -8 17 -4 -15 3 -26 0 -48
-9 -60 -13 -17 -14 -14 -11 26 2 41 2 42 -5 10 -6 -28 -7 -30 -9 -10 -2 39
-12 70 -23 70 -6 0 -8 -6 -5 -12z m64 -130 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m53 -65 c0 -17 -10 -16 -32 4 -26 23 -22 33 7 20 14 -7 25
-18 25 -24z"/>
<path d="M4880 5799 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5120 5662 c0 -6 9 -14 20 -17 11 -4 18 -10 15 -15 -3 -5 1 -11 7
-13 8 -4 7 -6 -4 -6 -9 -1 -24 6 -32 15 -15 14 -16 14 -16 -3 0 -19 5 -20 90
-13 l35 2 -30 9 c-21 5 -8 6 40 4 l70 -4 -83 14 c-45 7 -89 19 -97 25 -10 8
-15 9 -15 2z"/>
<path d="M5040 5645 c1 -17 29 -49 37 -41 4 3 -3 16 -15 29 -12 12 -22 17 -22
12z"/>
<path d="M5430 5630 c-12 -8 -10 -10 8 -10 13 0 32 -5 43 -10 11 -6 19 -7 19
-2 0 6 -8 12 -17 16 -10 3 -22 8 -28 11 -5 3 -17 0 -25 -5z"/>
<path d="M4461 5601 c-8 -6 -9 -11 -3 -15 6 -3 14 -1 17 5 9 14 2 20 -14 10z"/>
<path d="M4450 5564 c0 -19 22 -56 64 -107 31 -38 41 -45 53 -36 7 7 16 9 20
5 3 -3 2 -6 -4 -6 -6 0 -13 -7 -16 -14 -4 -10 -1 -13 9 -9 10 3 12 0 9 -10 -8
-20 44 -62 59 -47 5 5 17 10 25 10 13 -1 12 -4 -4 -16 -20 -15 -20 -15 5 -35
56 -45 124 -88 129 -82 4 3 0 8 -6 10 -9 3 -10 10 -3 23 13 24 -3 35 -29 20
-26 -13 -34 -13 -25 3 4 6 4 9 -1 5 -10 -9 -45 9 -45 24 0 7 4 7 13 0 9 -8 12
-4 13 14 2 23 1 24 -15 10 -20 -18 -31 -20 -31 -6 0 6 7 10 15 10 8 0 15 4 15
9 0 12 -37 24 -49 17 -12 -8 -21 17 -15 46 3 16 2 18 -3 7 -5 -11 -14 -14 -27
-9 -19 6 -19 7 2 21 20 15 20 15 -14 16 -19 1 -34 -4 -34 -10 0 -7 -7 -3 -16
8 -8 11 -12 26 -9 33 3 8 -2 23 -10 35 -8 12 -15 16 -15 9 0 -23 -16 -12 -38
26 -12 20 -22 32 -22 26z m74 -79 c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2
15 4 15 2 0 6 -7 10 -15z m105 -125 l16 -24 -27 19 c-15 11 -28 24 -28 28 0
15 23 1 39 -23z m153 -97 c-6 -2 -10 -9 -6 -14 3 -5 1 -9 -5 -9 -5 0 -12 4
-15 9 -6 9 10 19 29 19 6 0 4 -2 -3 -5z"/>
<path d="M4538 5513 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6040 5505 c-7 -8 -8 -15 -2 -15 5 0 15 7 22 15 7 8 8 15 2 15 -5 0
-15 -7 -22 -15z"/>
<path d="M5840 5339 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5857 5323 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M5760 5270 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M5802 5268 c-14 -7 -21 -17 -17 -26 3 -9 0 -12 -8 -9 -7 3 -13 -3
-13 -13 0 -15 6 -14 45 9 43 26 55 44 29 48 -7 1 -23 -3 -36 -9z m36 -5 c-10
-2 -18 -9 -18 -14 0 -5 -8 -9 -17 -9 -16 1 -16 2 1 15 11 8 27 14 35 14 13 -1
12 -2 -1 -6z"/>
<path d="M4856 5181 c22 -13 33 -15 50 -7 30 14 24 18 -32 21 l-47 4 29 -18z"/>
<path d="M4952 5189 c8 -5 2 -10 -15 -14 -15 -4 -27 -11 -27 -16 0 -12 17 -12
25 0 3 5 22 8 43 6 34 -4 35 -4 7 4 l-30 9 28 1 c40 2 33 17 -8 17 -19 0 -29
-3 -23 -7z"/>
<path d="M5530 5194 c8 -4 23 -13 32 -21 9 -8 25 -12 35 -9 10 3 25 1 33 -4
10 -6 5 -9 -19 -8 -18 1 -36 -3 -39 -9 -4 -5 -14 -13 -22 -16 -8 -4 1 -4 20
-1 31 5 180 62 180 68 0 2 -18 2 -39 2 -22 0 -37 -4 -35 -8 10 -16 -28 -20
-54 -7 -39 19 -49 22 -32 9 13 -10 12 -11 -5 -7 -46 13 -69 17 -55 11z m180
-8 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z"/>
<path d="M5630 5190 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M5520 5170 c0 -9 39 -25 45 -18 2 2 -7 8 -21 15 -13 6 -24 7 -24 3z"/>
<path d="M4945 5150 c-3 -5 14 -14 37 -20 100 -26 114 -29 133 -30 11 0 47 -3
80 -6 33 -3 78 -5 100 -5 22 0 60 0 85 1 25 0 70 6 100 14 l55 14 -51 -3 c-28
-2 -69 -7 -90 -10 -31 -5 -36 -4 -24 5 13 10 13 11 -2 6 -32 -10 -123 -18
-112 -9 7 4 -35 9 -95 9 -79 2 -119 7 -158 22 -29 11 -55 16 -58 12z"/>
<path d="M5020 5150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3208 3307 c-50 -14 -63 -22 -116 -71 -59 -54 -77 -100 -77 -196 0
-68 5 -93 22 -126 26 -49 89 -108 140 -130 53 -23 157 -23 215 -1 58 22 143
111 158 165 13 47 13 136 0 184 -13 46 -90 133 -141 159 -50 25 -141 32 -201
16z m158 -181 c28 -28 34 -42 34 -75 0 -77 -45 -131 -108 -131 -47 0 -87 24
-106 64 -42 90 9 175 108 176 30 0 45 -7 72 -34z"/>
<path d="M5908 3306 c-112 -40 -122 -66 -126 -323 l-3 -203 80 0 81 0 0 60 c0
53 2 60 20 60 18 0 20 7 20 70 0 64 -2 70 -20 70 -19 0 -21 5 -18 57 2 48 6
58 22 61 39 8 46 -22 46 -205 l0 -173 75 0 75 0 0 205 c0 220 -5 250 -51 291
-49 43 -130 55 -201 30z"/>
<path d="M6790 3308 c-52 -15 -90 -46 -111 -92 -16 -35 -19 -68 -19 -238 l0
-198 80 0 80 0 0 60 c0 53 2 60 20 60 18 0 20 7 20 70 0 64 -2 70 -20 70 -19
0 -21 5 -18 58 3 50 6 57 25 60 37 5 44 -34 41 -215 l-3 -163 78 0 78 0 -3
219 c-3 203 -5 222 -24 248 -44 61 -137 86 -224 61z"/>
<path d="M7255 3301 c-76 -34 -123 -116 -111 -192 3 -22 18 -64 33 -94 34 -68
25 -91 -31 -89 l-37 1 3 -76 3 -76 40 -8 c77 -17 165 26 202 96 26 50 22 124
-10 185 -39 75 -33 112 20 112 22 0 23 3 23 74 0 68 -2 74 -22 80 -39 10 -71
6 -113 -13z"/>
<path d="M2460 3045 l0 -265 80 0 80 0 0 186 0 186 66 -4 c84 -5 114 -33 114
-105 0 -38 -5 -50 -34 -79 -29 -29 -41 -34 -80 -34 l-46 0 0 -81 0 -82 65 6
c114 11 197 65 237 155 27 59 27 165 0 223 -54 119 -150 158 -389 159 l-93 0
0 -265z"/>
<path d="M3632 3093 c3 -206 4 -220 26 -254 56 -91 204 -102 291 -23 55 49 62
86 59 304 l-3 185 -75 0 -75 0 -5 -190 c-5 -188 -5 -190 -28 -193 -12 -2 -24
3 -27 10 -2 7 -6 94 -7 193 l-3 180 -78 3 -78 3 3 -218z"/>
<path d="M4110 3047 l0 -267 75 0 75 0 0 191 0 190 32 -3 c39 -4 52 -36 19
-45 -19 -5 -21 -13 -21 -74 0 -66 1 -68 26 -71 34 -4 56 -34 34 -48 -8 -5 -25
-10 -37 -10 -22 0 -23 -4 -23 -65 l0 -65 53 0 c82 0 131 40 150 120 11 45 -9
103 -48 136 -25 22 -31 33 -22 37 25 9 50 77 44 119 -11 84 -59 108 -228 115
l-129 6 0 -266z"/>
<path d="M4552 3097 l3 -214 28 -36 c37 -48 75 -67 137 -67 l50 0 0 75 c0 73
-1 75 -25 75 -14 0 -26 1 -27 3 -1 1 -4 85 -7 187 l-6 185 -78 3 -78 3 3 -214z"/>
<path d="M4949 3301 c-57 -8 -93 -31 -116 -74 -26 -47 -26 -326 0 -374 29 -53
80 -73 192 -73 l95 0 0 75 0 75 -75 0 c-73 0 -75 1 -75 25 0 23 3 24 68 27
l67 3 3 57 3 57 -63 3 c-57 3 -63 5 -66 26 -3 21 0 22 68 22 l71 0 -3 78 -3
77 -60 1 c-33 1 -81 -1 -106 -5z"/>
<path d="M5210 3046 l0 -266 80 0 80 0 0 186 0 186 66 -4 c58 -4 70 -8 90 -32
43 -50 24 -149 -33 -175 -13 -6 -42 -11 -64 -11 l-40 0 3 -77 3 -78 58 1 c81
2 137 27 190 83 52 56 71 108 70 197 -1 101 -52 176 -151 221 -46 22 -70 25
-202 29 l-150 5 0 -265z"/>
<path d="M6214 3297 c-3 -8 -4 -43 -2 -78 l3 -64 58 -3 57 -3 0 -184 0 -185
80 0 80 0 0 185 0 185 60 0 61 0 -3 78 -3 77 -193 3 c-158 2 -193 0 -198 -11z"/>
<path d="M7460 3102 c0 -199 1 -211 23 -253 27 -51 66 -76 128 -85 97 -13 195
46 218 133 7 26 10 112 9 225 l-3 183 -75 0 -75 0 -5 -190 c-5 -178 -6 -190
-24 -193 -40 -8 -46 22 -46 210 l0 178 -75 0 -75 0 0 -208z"/>
<path d="M7937 3303 c-4 -3 -7 -123 -7 -265 l0 -258 80 0 80 0 0 191 0 190 32
-3 c39 -4 52 -36 19 -45 -19 -5 -21 -13 -21 -74 0 -66 1 -69 24 -69 13 0 29
-6 35 -14 18 -22 2 -46 -30 -46 l-29 0 0 -65 0 -65 45 0 c103 0 155 52 155
154 0 56 -2 61 -39 97 -26 24 -36 39 -27 42 7 3 21 22 31 42 30 64 5 139 -55
170 -32 16 -280 32 -293 18z"/>
<path d="M8552 3270 c-29 -10 -85 -37 -125 -60 -39 -23 -63 -38 -52 -35 161
57 247 65 261 23 24 -77 -279 -404 -556 -599 l-85 -60 -57 60 c-32 34 -60 61
-62 61 -2 0 -7 -53 -10 -117 -4 -65 -9 -141 -12 -169 l-6 -51 124 -7 c67 -4
144 -9 171 -13 26 -3 47 -2 47 3 0 5 -23 33 -50 62 -28 29 -50 57 -50 61 0 5
15 20 33 34 38 28 326 313 378 372 46 53 125 157 171 225 53 78 69 175 35 218
-14 18 -94 14 -155 -8z"/>
<path d="M9367 1442 l-1429 -1429 189 -6 c103 -4 747 -7 1431 -7 l1242 0 0
1435 c0 789 -1 1435 -3 1435 -1 0 -645 -643 -1430 -1428z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
